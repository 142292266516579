/**
 * Possible duplication with global constants that will need to be refactored.
 */
export const WorkflowStatus = Object.freeze({
  Ingested: 'ingested',
  Failed: 'failed',
  Uploaded: 'uploaded',
  Created: 'created',
} as const);

export const ApprovalStatus = Object.freeze({
  Pending: 'pending',
  Rejected: 'rejected',
  Approved: 'approved',
  Reviewed: 'reviewed',
  Deleted: 'deleted', // Review status
  DeletedByUser: 'deleted_by_user', // Media hadron status
  Published: 'published',
  DataLicensing: 'data_licensing',
  Returned: 'returned',
} as const);

export const DataCatalogApprovalStatus = Object.freeze({
  Approved: 'Approved',
  Deleted: 'Deleted',
});

export const MediaType = Object.freeze({
  Photo: 'photo',
  Video: 'video',
  Image: 'image', // CC-API uses image instead of photo
} as const);

export type MediaOfTypeImageOrVideo = typeof MediaType.Photo | typeof MediaType.Video;

export const SortOrder = Object.freeze({
  MostPopular: 'popular',
  Newest: 'newest',
  Oldest: 'oldest',
});

export const SearchFilter = Object.freeze({
  Keywords: 'keywords',
  Id: 'id',
  DateUploaded: 'date_uploaded',
});

export const MediaTypeToPrefix = Object.freeze({
  [MediaType.Image]: 'P',
  [MediaType.Photo]: 'P',
  [MediaType.Video]: 'F',
});

export const PortfolioTabs = Object.freeze({
  NotSubmitted: 'not_submitted',
  Pending: 'pending',
  Published: 'published',
  RecentlyReviewed: 'recently_reviewed',
  TopPerformers: 'top_performers', // Added since it uses the portfolio empty state
} as const);

/**
 * This refers to CC-API MEDIA_PREFIX_TO_TYPE constants.
 */
export const MediaTypePrefix = Object.freeze({
  Upload: 'U',
  Photo: 'P',
  Video: 'F',
  CSV: 'C',
});

export const ImageType = Object.freeze({
  Photo: 'photo',
  Illustration: 'illustration',
});

export const Usage = Object.freeze({
  Commercial: 'commercial',
  Editorial: 'editorial',
});

export const Exclusivity = Object.freeze({
  Exclusive: 'exclusive',
  NotExclusive: 'not-exclusive',
  SelfDesignating: 'self-designating',
});

export const SubmitterNote = Object.freeze({
  None: '',
  CaseNumber: 'sf_case_number',
  PreviouslySubmitted: 'previously_submitted',
  IllustrationWithoutReference: 'illustration_tablet',
});

export const ASSETS_PER_GRID_PAGE = 100;
export const CSV_ERRORS_MAX = 10000;
export const CSV_ERRORS_DISPLAYED = 25;
export const MAX_ASSET_SELECTION_COUNT = 100;

export const UploadType = Object.freeze({
  Image: 'image',
  Video: 'video',
  Application: 'application',
});

export const ValidationStatus = Object.freeze({ Ready: 'ready', Failed: 'failed', Incomplete: 'incomplete' });

export const PHOTO_SUBMIT_LIMIT_REACHED = 'ERROR_PHOTO_MAX_SUBMISSION_COUNT_EXCEEDED';
export const VIDEO_SUBMIT_LIMIT_REACHED = 'ERROR_VIDEO_MAX_SUBMISSION_COUNT_EXCEEDED';

export const SORT_NEWEST = 'NEWEST';
export const SORT_OLDEST = 'OLDEST';

export const ASSET_STATUS = 'assetStatus';

export const Channel = Object.freeze({
  DataMarketplace: 'DATA_MARKETPLACE',
  Shutterstock: 'SHUTTERSTOCK',
  BigStock: 'BIGSTOCK',
});

export const INITIAL_FILTER_TYPE = SearchFilter.Keywords;
export const INITIAL_SORT_ORDER = SortOrder.MostPopular;
export const ONLY_ENGLISH_CHARS_AND_NUMBERS_REGEX = /[^a-zA-Z0-9 ]/g;
export const ONLY_NUMBERS_REGEX = /\D/g;
